import React from "react";

const ActivismSection = () => {
  return (
    <section className="bg-activism bg-no-repeat bg-cover bg-top bg-fixed bg- text-gray-100 px-6 p-8">
      <div className="max-w-7xl mx-auto items-center justify-center lg:px-6">
        <h2 className="text-3xl font-bold text-center text-yellow-400">
          Sejak Muda Memiliki Jiwa Aktivis
        </h2>
        <div className="mt-4 justify-center items-center">
          <p className="mt-6 text-center mx-auto">
            Tutur Sutikno lahir pada tanggal 28 Februari 1967 di Pesawaran,
            Lampung.
          </p>
          <p className="mt-3 text-center mx-auto lg:w-[900px]">
            Sejak muda, Tutur Sutikno aktif mengikuti organisasi sebagai
            mahasiswa di Universitas Lampung untuk menyuarakan kepeduliannya
            terhadap isu-isu sosial dan politik.
          </p>
          <div className="flex justify-center items-center mt-8">
            <img
              src="/img/smbullets1.png"
              alt=" "
              className="block w-[60%] lg:w-[25%] h-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActivismSection;
