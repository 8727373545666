import React from "react";

const HeroSection = () => {
  return (
    <section className="h-fit flex justify-center items-center pt-[52px] lg:pt-[50px]">
      <div className="w-full h-full flex justify-center items-center">
        <img
          src="/img/Baliho.png"
          alt="Tutur Sutikno"
          className="w-full h-auto object-cover"
          layout="responsive"
          width={300}
          height={300}
        />
      </div>
    </section>
  );
};

export default HeroSection;
