import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import BiographySection from "./components/BiographySection";
import ActivismSection from "./components/ActivismSection";
import PoliticianSection from "./components/PoliticianSection";
import EntrepreneurSection from "./components/EntrepreneurSection";
import DocumentSection from "./components/DocumentSection";
import Footer from "./components/Footer";
import NewsSection from "./components/NewsSections";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Navbar />
    <HeroSection />
    <NewsSection />
    <BiographySection />
    <ActivismSection />
    <PoliticianSection />
    <EntrepreneurSection />
    <DocumentSection />
    <Footer />
  </React.StrictMode>
);

reportWebVitals();
