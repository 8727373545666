import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";

const newsArticles = [
  {
    title:
      "DPP PAN Dukung Penuh & Siap All Out untuk Menjadikan Tutur Sutikno Wakil Bupati Bogor 2024-2029",
    date: "2024-07-18",
    link: "https://bogoronline.com/2024/07/dpp-pan-dukung-penuh-siap-all-out-untuk-menjadikan-tutur-sutikno-wakil-bupati-bogor-2024-2029/",
    img: "/img/news/1.jpeg",
  },
  {
    title:
      "H. Supono Benarkan Tutur Sutikno Telah Dapat Rekom dari DPP PAN Untuk Maju diPilkada Kabupaten Bogor",
    date: "2024-07-15",
    link: "https://inionline.id/2024/07/h-supono-benarkan-tutur-sutikno-telah-dapat-rekom-dari-dpp-pan-untuk-maju-dipilkada-kabupaten-bogor/",
    img: "/img/news/2.jpeg",
  },
  {
    title:
      "Profesional & Politikus Sarat Pengalaman, Tutur Sutikno Kandidat Wakil Bupati Bogor yang Visioner",
    date: "2024-07-12",
    link: "https://jabarcyber.com/2024/07/profesional-politikus-sarat-pengalaman-tutur-sutikno-kandidat-wakil-bupati-bogor-yang-visioner/",
    img: "/img/news/3.png",
  },
  {
    title:
      "Menjelang Dibukanya Pendaftaran Cagub dan Cawabup, Gambar Tutur Sutikno Hiasi Sejumlah Ruas Jalan",
    date: "2024-07-10",
    link: "https://www.redaksitoday.com/regional/amp/34474014/menjelang-dibukanya-pendaftaran-cagub-dan-cawabup-gambar-tutur-sutikno-hiasi-sejumlah-ruas-jalan",
    img: "/img/news/4.webp",
  },
  {
    title:
      "Demokrat Ngaku Belum Final Dukung PAN Sodorkan Tutur Sutikno jadi Cawabup",
    date: "2024-07-09",
    link: "https://rasioo.id/2024/07/09/demokrat-ngaku-belum-final-dukung-jaro-ade-pan-sodorkan-tutur-sutikno-jadi-cawabup-bogor/",
    img: "/img/news/5.jpeg",
  },
  {
    title:
      "Heboh Penggusuran PKL di Puncak, Ini Solusi dari Bakal Cawabup Bogor",
    date: "2024-07-21",
    link: "https://www.merdeka.com/peristiwa/heboh-penggusuran-pkl-di-puncak-ini-solusi-dari-bakal-cawabup-bogor-168226-mvk.html",
    img: "/img/news/6.jpeg",
  },
];

const NewsSection = () => {
  return (
    <section className="py-12 bg-gray-100" id="berita">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-8 text-center text-blue-900">
          Berita Kang Tutur
        </h2>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{ delay: 2500 }}
          loop={true}
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {newsArticles.map((article, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white shadow-md rounded-lg overflow-hidden h-full flex flex-col">
                <img
                  src={article.img}
                  alt={article.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4 flex-grow flex flex-col">
                  <h3 className="text-xl font-semibold mb-2">
                    {article.title}
                  </h3>
                  <p className="text-gray-600 mb-4">{article.date}</p>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-auto text-blue-500 hover:text-blue-900"
                  >
                    Baca Selengkapnya
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default NewsSection;
