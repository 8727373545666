import React from "react";
import { FaWhatsapp, FaInstagram, FaTiktok } from "react-icons/fa";

const Footer = () => {
  const whatsappNumber = "6285215829996";
  const instagramUsername = "drs_tutur_sutikno";
  const tiktokUsername = "@sadulursabatur";

  return (
    <footer className="bg-blue-900 text-white py-8">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-4">
          <h3 className="text-xl font-medium text-yellow-400">
            Akun Official Tutur Sutikno
          </h3>
        </div>
        <div className="flex justify-center space-x-6">
          <a
            href={`https://wa.me/${whatsappNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow-400 transition duration-300"
          >
            <FaWhatsapp className="text-2xl" />
          </a>
          <a
            href={`https://www.tiktok.com/${tiktokUsername}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow-400 transition duration-300"
          >
            <FaTiktok className="text-2xl" />
          </a>
          <a
            href={`https://www.instagram.com/${instagramUsername}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-yellow-400 transition duration-300"
          >
            <FaInstagram className="text-2xl" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
