import React from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const BiographySection = () => {
  return (
    <section className="bg-gray-100 text-black pt-0">
      <img src="/img/accent.png" alt="" className="w-full mt-0" />
      <div className="max-w-7xl mx-auto pt-8">
        <div className="lg:flex lg:items-start px-6 pb-8">
          <div className="lg:w-2/3">
            <h2 className="text-3xl font-bold text-center lg:text-left text-blue-800 lg:w-[500px]">
              Perjuangan yang Tak Pernah Henti untuk Masyarakat
            </h2>
            <p className="mt-6 text-center lg:text-justify lg:w-[750px]">
              Keberhasilan Tutur Sutikno didasari oleh prinsip yang selalu ia
              pegang teguh. Dikenal sebagai sosok yang amanah, disiplin, dan
              pantang menyerah, baik dalam bisnis maupun sebagai aktivis.
            </p>
            <p className="mt-4 text-center lg:w-[750px] lg:text-justify">
              Dengan adanya Pilkada 2024, Tutur Sutikno terpanggil untuk ikut
              serta dalam kontestasi pemilihan kepala daerah di Kabupaten Bogor.
              Setelah 30 tahun tinggal di Kabupaten Bogor, Tutur Sutikno merasa
              prihatin dengan permasalahan yang belum terselesaikan.
            </p>
            <p className="mt-4 text-center lg:w-[750px] lg:text-justify">
              Dengan pengalaman dan dedikasinya, ia bertekad membawa perubahan
              positif untuk masyarakat Kabupaten Bogor.
            </p>
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-auto lg:w-1/3 lg:flex lg:justify-center lg:items-center lg:pt-6">
            <Swiper
              modules={[Autoplay, Pagination]}
              slidesPerView={1}
              spaceBetween={30}
              pagination={{ clickable: true }}
              autoplay={{ delay: 2500, disableOnInteraction: false }}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide className="flex lg:justify-center">
                <img
                  src="/img/masyarakat/1.JPG"
                  alt=" "
                  className="w-auto h-auto aspect-[16/9] object-cover rounded-[10px]"
                />
              </SwiperSlide>
              <SwiperSlide className="flex justify-center">
                <img
                  src="/img/masyarakat/2.JPG"
                  alt=" "
                  className="w-auto h-auto aspect-[16/9] object-cover rounded-[10px]"
                />
              </SwiperSlide>
              <SwiperSlide className="flex justify-center">
                <img
                  src="/img/masyarakat/3.jpg"
                  alt=" "
                  className="w-auto h-auto aspect-[16/9] object-cover rounded-[10px]"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BiographySection;
