import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

const DocumentationSection = () => {
  return (
    <section
      id="document"
      className="bg-documentations bg-no-repeat bg-cover bg-bottom bg-fixed"
    >
      <div className="max-w-7xl mx-auto py-8 px-6">
        <h2 className="text-3xl font-bold text-center text-blue-900 mb-6">
          Dokumentasi Kegiatan
        </h2>
        <div className="mt-4 relative">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="aspect-w-16 aspect-h-9">
                  <img
                    src={image.src}
                    alt={image.caption}
                    className="w-auto h-auto aspect-[16/9] object-cover rounded-[10px]"
                  />
                </div>
                <div className="text-center mt-6 font-medium text-blue-900 text-xl">
                  <p>{image.caption}</p>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

const images = [
  {
    src: "/img/dokumentasi/Dengan Ulama Kab Bogor.jpg",
    caption: "Dengan Ulama Kab. Bogor",
  },
  {
    src: "/img/dokumentasi/Groundbreaking Pembangunan STT Muhammadiyah Cileungsi .JPG",
    caption: "Groundbreaking Pembangunan STT Muhammadiyah Cileungsi",
  },
  {
    src: "/img/dokumentasi/Konsolidasi di Desa Dayeuh Cileungsi.jpg",
    caption: "Konsolidasi di Desa Dayeuh Cileungsi",
  },
  {
    src: "/img/dokumentasi/Konsolidasi Tim Pak Tutur Kec Cigombong.jpg",
    caption: "Konsolidasi Tim Kang Tutur Kec Cigombong",
  },
  {
    src: "/img/dokumentasi/Pemberian Bantuan kepada Masyarakat Tlajung Gn.Putri .JPG",
    caption: "Pemberian Bantuan kepada Masyarakat Tlajung Gn.Putri ",
  },
  {
    src: "/img/dokumentasi/Rakernas PAN 4 Jakarta.JPG",
    caption: "Rakernas PAN 4 Jakarta",
  },
  {
    src: "/img/dokumentasi/Rakorwil DPW PAN MALUT.jpg",
    caption: "Rakorwil DPW PAN MALUT",
  },
  {
    src: "/img/dokumentasi/Sapa Warga & Silaturahmi di Cileungsi.jpg",
    caption: "Sapa Warga & Silaturahmi di Cileungsi",
  },
];

export default DocumentationSection;
