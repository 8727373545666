const PoliticianSection = () => {
  return (
    <section className="bg-politician bg-no-repeat bg-cover bg-top bg-fixed text-gray-100 pt-0">
      <img src="/img/accent.png" alt="" className="w-full mt-0 scale-x-[-1]" />
      <div className="max-w-7xl mx-auto px-6 py-8 ">
        <h2 className="text-3xl font-bold text-center text-yellow-400">
          Perjalanan Sebagai Politisi
        </h2>
        <div className="flex justify-center items-center mt-8">
          <img
            src="/img/bullets2.png"
            alt=" "
            className="hidden lg:block lg:w-[70%] h-auto"
          />
          <img
            src="/img/smbullets2.png"
            alt=" "
            className="block w-[60%] lg:hidden h-auto"
          />
        </div>
        <div className="flex justify-center items-center mt-12">
          <img
            src="/img/bullets3.png"
            alt=" "
            className="hidden lg:block lg:w-[70%] h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default PoliticianSection;
