const EntrepreneurSection = () => {
  return (
    <section className="bg-entrepreneursm bg-no-repeat bg-cover bg-right-top bg-fixed sm-md:bg-entrepreneursm lg:bg-entrepreneurlg ">
      <div className="max-w-7xl mx-auto py-8 px-6">
        <h2 className="text-3xl font-bold text-center text-blue-900">
          Melangkah Sebagai Pengusaha Visioner
        </h2>
        <div className="text-black mt-4 text-center">
          <p className="mt-6 text-center mx-auto lg:w-[900px]">
            Perjalanan karir Tutur membuahkan kesuksesan karena tekad yang kuat,
            jujur, dan profesional.
          </p>
          <p className="mt-1 text-center mx-auto lg:w-[900px]">
            Dengan segudang pengalamannya, Tutur berusaha membuka banyak
            lapangan pekerjaan.
          </p>
          <div className="flex justify-center items-center mt-7">
            <img
              src="/img/bullets4.png"
              alt=" "
              className="lg:block lg:w-[40%] w-[95%] h-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EntrepreneurSection;
