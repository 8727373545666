import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = (href) => {
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setIsOpen(false);
  };

  return (
    <nav
      className={`fixed w-full top-0 z-50 transition duration-300 ${
        scroll ? "bg-gray-100 text-blue-900" : "bg-blue-900 text-white"
      } p-4`}
    >
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="hidden md:flex items-center space-x-4">
          <a className="hover:text-yellow-400 font-normal" href="#">
            Home
          </a>
          <a
            id="berita"
            className="hover:text-yellow-400 font-normal no-outline" // Add custom class here
            href="#berita"
            onClick={() => handleLinkClick("berita")}
          >
            Berita
          </a>
          <a
            id="dokumentasi"
            className="hover:text-yellow-400 font-normal no-outline" // Add custom class here
            href="#document"
            onClick={() => handleLinkClick("document")}
          >
            Dokumentasi
          </a>
        </div>
        <div className="hidden md:flex items-center">
          <a
            href="https://wa.me/6285215829996"
            target="_blank"
            className="hover:text-[#25D366] flex items-center space-x-2"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={24} />
            <span>Hubungi kami</span>
          </a>
        </div>
        <div className="md:hidden flex items-center">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`text-${
              scroll ? "blue-900" : "white"
            } focus:outline-none`}
          >
            <FaBars size={24} />
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden mt-2 space-y-2">
          <a
            className="block hover:text-yellow-400 font-normal no-outline"
            href="#"
          >
            Home
          </a>
          <a
            className="block hover:text-yellow-400 font-normal no-outline"
            href="#berita"
            onClick={() => handleLinkClick("berita")}
          >
            Berita
          </a>
          <a
            className="block hover:text-yellow-400 font-normal no-outline"
            href="#document"
            onClick={() => handleLinkClick("document")}
          >
            Dokumentasi
          </a>
          <a
            href="https://wa.me/6285215829996"
            target="_blank"
            className="block hover:text-[#25D366] flex items-center space-x-2"
            rel="noopener noreferrer"
          >
            <FaWhatsapp size={24} />
            <span>Hubungi kami</span>
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
